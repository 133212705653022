
.link-target {
  &:hover {
    color: #fff;
   }
}
.list {
  list-style: none;
  margin-left: 2rem;
  li {
    padding: 1rem 1rem 1rem;
    &::before {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: #ED1B2F;
      content: "";
      display: inline-block;
      margin-left: -1rem;
      margin-right: 1.5rem;
      position: relative;
      top: -0.2rem;
      @media (max-width: 767px) {
        width: 6px;
        height: 6px;
      }
    }
  }
}
.accordion__header h2:after {
  display: none!important;
}
.inline-menu {
  position: relative!important;
  top: auto;
  right: auto;
}

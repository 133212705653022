
.attach {
  label {
    margin-bottom: 0!important;
  }
  @media (max-width: 768px){
    .title-block {
      margin-bottom: 10px;
    }
  }
}

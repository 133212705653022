
@import "src/assets/style/variables";
.form-result {
  background: url("/pic/ffg/union-up.svg") #fff center bottom no-repeat;
  .modal-body {
    padding: 0 4rem 0;
  }
  .btn-rounded {
    margin-bottom: 3.2rem;
    border-radius: 2.4rem;
  }
}
.modal-content  {
  &.danger {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: .5;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
}
.form-item.education, .modal-header {
  z-index: 3;
}
